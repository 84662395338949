export const environment = {
  production: false,
  map_box_token: '',
  firebase: {
    apiKey: 'AIzaSyBdavoxit41UKs9Jvngrdvrq_-nRz52ANo',
    authDomain: 'syndiyo-ef93f.firebaseapp.com',
    projectId: 'syndiyo-ef93f',
    storageBucket: 'syndiyo-ef93f.appspot.com',
    messagingSenderId: '875653823199',
    appId: '1:875653823199:web:3b30786f0c24615f61476a',
    measurementId: 'G-87Q86HSN57',
    vapidKey:
      'BPWgdi_SxyrE5w_s3B1EHw4Vt08NfTWsQoGRB9ee4HV1hhx45pFDmTyBCRdm5ESkA7P2ity-JbH0-WeSrDKTH0M',
  },
  adminEmail: 'admin@admin.com',
  stripe: {
    sk: '',
  },
};
